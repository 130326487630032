






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { actions } from '@/utils/property'

@Component({
  name: 'PromotionalActions'
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any

  created() {
    if (!this.property.meta?.actions?.length) {
      this.property.meta.actions = actions
    }
  }
}
